<template>
  <section class="product_m">
    <img
      class="banner"
      :src="$t('locale') == 'zh' ? banner : banner_en"
      alt=""
    />
    <ul>
      <p class="big_title">{{ $t("PC.components.FooterC.yl2det") }}</p>
      <li>
        <img
          src="../../../assets/zh/images-mobile/productd_img7@3x.png"
          alt=""
        />
        <div class="content_box cb0">
          <p class="title">{{ $t("PC.Product.index.2d06a1") }}</p>
          <p class="text">
            {{ $t("PC.Product.index.8eda57") }}
          </p>
          <router-link class="more" to="CoovallyHome">{{
            $t("PC.Product.index.c09306")
          }}</router-link>
        </div>
      </li>
      <li>
        <img
          src="../../../assets/zh/images-mobile//productd_img1@2x.png"
          alt=""
        />
        <div class="content_box cb1">
          <p class="title">{{ $t("PC.Product.index.h3g3ll") }}</p>
          <p class="text">
            {{ $t("PC.Product.index.jkkevd") }}
          </p>
          <router-link class="more" to="AIShop">{{
            $t("PC.Product.index.bojcor")
          }}</router-link>
        </div>
      </li>
      <!-- <li>
        <img
          src="../../../assets/zh/images-mobile//productd_img3@2x.png"
          alt=""
        />
        <div class="content_box cb2">
          <p class="title">{{ $t("PC.Product.index.a3vyku") }}</p>
          <p class="text">
            {{ $t("PC.Product.index.zz1uhs") }}
          </p>
        </div>
      </li> -->
      <!-- <li>
        <img
          src="../../../assets/zh/images-mobile//productd_img5@2x.png"
          alt=""
        />
        <div class="content_box cb3">
          <p class="title">{{ $t("PC.Product.index.y31rsa") }}</p>
          <p class="text">
            {{ $t("PC.Product.index.rvy2c7") }}
          </p>
          <router-link class="more" to="POMA">{{
            $t("PC.Product.index.bojcor")
          }}</router-link>
        </div>
      </li> -->
      <li>
        <img
          src="../../../assets/zh/images-mobile/productd_img4@2x.jpg"
          alt=""
        />
        <div class="content_box cb4">
          <p class="title">{{ $t("PC.Product.index.5pvjc1") }}</p>
          <p class="text">{{ $t("PC.Product.index.zqerls") }}</p>
          <router-link class="more" to="Slager">{{
            $t("PC.Product.index.bojcor")
          }}</router-link>
        </div>
      </li>
    </ul>
    <FooterB></FooterB>
  </section>
</template>

<script>
import FooterB from "../components/FooterB";
export default {
  components: {
    FooterB,
  },
  data() {
    return {
      banner: require("../../../assets/zh/images-mobile//productd_banner1@2x.jpg"),
      banner_en: require("../../../assets/en/images-mobile//productd_banner1@2x.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.product_m {
  margin-top: 48px;
  word-break: break-word !important;

  img.banner {
    width: 100%;
    height: 150px;
  }

  ul {
    box-sizing: border-box;
    padding: 20px;

    & > p.big_title {
      color: #333;
      text-align: center;
      padding-bottom: 8px;
      font-size: 16px;
      line-height: 22px;
    }

    li {
      width: 100%;
      overflow: hidden;
      margin-bottom: 16px;

      img {
        width: 100%;
      //   height: 133px;
      }

      .content_box {
        padding: 12px 16px 18px;
        background-image: url("../../../assets/zh/images-mobile//productd_img2.png");
        background-size: contain;
        background-repeat: no-repeat;
        border-bottom: 2px solid #224386;

        p.title {
          color: #333;
          font-size: 14px;
          line-height: 20px;
          padding-bottom: 8px;
        }

        .text {
          color: #666;
          font-size: 12px;
          line-height: 18px;
          width: 100%;
          font-size: 12px;
          line-height: 21px;
        }

        .more {
          display: inline-block;
          margin-top: 12px;
          width: auto;
          height: 25px;
          padding: 0 5px;
          border: 1px solid #666666;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          text-align: center;
        }
      }

      .cb2 {
        background-image: url("../../../assets/zh/images-mobile//productd_img4@2x.png");
      }

      .cb3 {
        background-image: url("../../../assets/zh/images-mobile//productd_img6@2x.png");
      }
    }
  }
}
</style>
